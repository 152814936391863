import { getContext, setContext } from "svelte";
import type { DistanceSystem } from "$lib/distance_units";
import type { ProviderName } from "$lib/server/entities/auth_provider/AuthProviderEntity";
import type { SelectablePermission } from "$lib/server/entities/permission/PermissionEntity";
import type { ProfilePageVisibilityPermission, AccountSubscriptionStatus } from "$lib/server/entities/user_account/UserAccountEntity";
import type { MapLayerType } from "$lib/ui_types";
import { DEFAULT_MAP_STYLE } from "$lib/ui_constants";
import { DEFAULT_ANONYMOUS_PROFILE_NAME } from "$lib/auth/auth_constants";

export interface UserSessionSettings {
    display_name: string, 
    profile_picture_pathname?: string, 
    cover_picture_pathname?: string, 
    profile_picture_url?: string,
    analytics_enabled: boolean,
    profile_page_visibility_permission: ProfilePageVisibilityPermission,
    default_permission: SelectablePermission,
    preferred_measurement_unit: DistanceSystem,
    enabled: boolean,
    auth_provider: ProviderName,
    contact_enabled: boolean,
    default_map_style: MapLayerType,
    subscription_status: AccountSubscriptionStatus,
    is_insider: boolean,
    is_subscribed: boolean
};

export type UserSession = {
    user_account_uuid: string, 
    user_settings: UserSessionSettings
};

class UserSessionData implements UserSessionSettings { 

    private _user_account_uuid: string;
    private _display_name: string = $state(DEFAULT_ANONYMOUS_PROFILE_NAME);
    private _profile_picture_pathname?: string = $state();
    private _cover_picture_pathname?: string = $state();
    private _profile_picture_url?: string = $state();
    private _analytics_enabled: boolean = $state(false);
    private _profile_page_visibility_permission: ProfilePageVisibilityPermission = $state('public');
    private _default_permission: SelectablePermission = $state('private');
    private _preferred_measurement_unit: DistanceSystem = $state('metric');
    private _enabled: boolean = $state(false);
    private _auth_provider: ProviderName = $state('credentials');
    private _default_map_style: MapLayerType = $state(DEFAULT_MAP_STYLE);
    private _subscription_status: AccountSubscriptionStatus = $state('freemium');
    private _is_insider: boolean = $state(false);
    private _contact_enabled: boolean = $derived(this._auth_provider !== 'apple' && this._auth_provider !== undefined);

    constructor(user_account_uuid: string, settings: UserSessionSettings) {

        this._user_account_uuid = user_account_uuid;
        this._auth_provider = settings.auth_provider;
        this._is_insider = settings.is_insider;

        // leverages the setters
        this.display_name = settings.display_name;
        this.profile_picture_pathname = settings.profile_picture_pathname;
        this.cover_picture_pathname = settings.cover_picture_pathname;
        this.profile_picture_url = settings.profile_picture_url;
        this.analytics_enabled = settings.analytics_enabled;
        this.profile_page_visibility_permission = settings.profile_page_visibility_permission;
        this.default_permission = settings.default_permission;
        this.preferred_measurement_unit = settings.preferred_measurement_unit;
        this.enabled = settings.enabled;
        this.default_map_style = settings.default_map_style;
        this.subscription_status = settings.subscription_status;
    }

    /* display name */
    set display_name(value: string) {
        this._display_name = value;
    }

    get display_name() {
        return this._display_name;
    }

    /* profile picture pathname */
    set profile_picture_pathname(value: string | undefined) {
        this._profile_picture_pathname = value;
    }

    get profile_picture_pathname() {
        return this._profile_picture_pathname;
    }

    /* cover picture pathname */
    set cover_picture_pathname(value: string | undefined) {
        this._cover_picture_pathname = value;
    }

    get cover_picture_pathname() {
        return this._cover_picture_pathname;
    }

    /* profile picture url */
    set profile_picture_url(value: string | undefined) {
        this._profile_picture_url = value;
    }

    get profile_picture_url() {
        return this._profile_picture_url;
    }

    /* analytics enabled */
    set analytics_enabled(value: boolean) {
        this._analytics_enabled = value;
    }

    get analytics_enabled() {
        return this._analytics_enabled;
    }

    /* profile page visibility permission */
    set profile_page_visibility_permission(value: ProfilePageVisibilityPermission) {
        this._profile_page_visibility_permission = value;
    }

    get profile_page_visibility_permission() {
        return this._profile_page_visibility_permission;
    }

    /* default permission */
    set default_permission(value: SelectablePermission) {
        this._default_permission = value;
    }

    get default_permission() {
        return this._default_permission;
    }

    /* preferred measurement unit */
    set preferred_measurement_unit(value: DistanceSystem) {
        this._preferred_measurement_unit = value;
    }

    get preferred_measurement_unit() {
        return this._preferred_measurement_unit;
    }

    /* enabled */
    set enabled(value: boolean) {
        this._enabled = value;
    }

    get enabled() {
        return this._enabled;
    }

    /* contact enabled */
    get contact_enabled() {
        return this._contact_enabled;
    }

    /* default map style */
    set default_map_style(value: MapLayerType) {
        this._default_map_style = value;
    }

    get default_map_style() {
        return this._default_map_style;
    }

    /* subscription status */
    set subscription_status(value: AccountSubscriptionStatus) {
        this._subscription_status = value;
    }

    get subscription_status() {
        return this._subscription_status;
    }

    get is_subscribed() {
        return this._subscription_status === 'paying';
    }

    /* user account uuid */
    get user_account_uuid() {
        return this._user_account_uuid;
    }

    /* auth provider */
    get auth_provider() {
        return this._auth_provider;
    }

    /* is insider */
    get is_insider() {
        return this._is_insider;
    }
}

export function initialize_session(user_account_uuid: string, user_settings: UserSessionSettings) {

    let user_session_data = new UserSessionData(user_account_uuid, user_settings);
    setContext<UserSession>('user_session', {user_account_uuid, user_settings: user_session_data});
    // console.log("Initialized session (0) -----------> ", user_session_data);    
}

export function get_session() {
    return getContext<UserSession>('user_session');
}